"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Nav = /** @class */ (function () {
    function Nav(nav) {
        var _a, _b, _c;
        this.closeOffset = 20;
        this.nav = nav;
        this.initialNavBarPosition = this.nav.offsetTop + this.nav.offsetHeight;
        this.navBarPosition = this.initialNavBarPosition;
        this.burger = document.querySelector("#burger");
        this.close = document.querySelector("#close");
        this.backButtons = Array.from((_a = this.nav.querySelectorAll(".back-button")) !== null && _a !== void 0 ? _a : []);
        this.submenuLinks = Array.from((_b = this.nav.querySelectorAll(".menu-item-has-children > a")) !== null && _b !== void 0 ? _b : []);
        this.allLinks = Array.from((_c = this.nav.querySelectorAll(".menu > .menu-item > a")) !== null && _c !== void 0 ? _c : []);
        this.init();
    }
    Nav.prototype.init = function () {
        var _this = this;
        var _a, _b;
        this.allLinks.forEach(function (el) {
            el.addEventListener("mouseenter", function (e) {
                _this.toggleSubmenu(e);
            });
        });
        (_a = this.close) === null || _a === void 0 ? void 0 : _a.addEventListener("click", function () {
            _this.nav.classList.remove("active");
        });
        (_b = this.burger) === null || _b === void 0 ? void 0 : _b.addEventListener("click", function () {
            _this.nav.classList.add("active");
        });
        this.backButtons.forEach(function (el) {
            el.addEventListener("click", function (e) {
                _this.submenuLinks.forEach(function (el) {
                    el.classList.remove("active");
                });
            });
        });
        document.addEventListener("mousemove", function (e) {
            // mouse y position
            var mouseY = e.clientY;
            if (mouseY > _this.navBarPosition) {
                _this.submenuLinks.forEach(function (el) {
                    el.classList.remove("active");
                });
            }
        });
    };
    Nav.prototype.toggleSubmenu = function (e) {
        e.preventDefault();
        var link = e.currentTarget;
        this.allLinks.forEach(function (el) {
            if (el !== link) {
                el.classList.remove("active");
            }
        });
        if (!link.parentElement.classList.contains("menu-item-has-children")) {
            this.navBarPosition = this.initialNavBarPosition;
            return;
        }
        this.navBarPosition = this.initialNavBarPosition + 200; // temp so doens't instantly close
        link.classList.add("active");
        var submenu = link.nextElementSibling;
        var submenuHeight = submenu.offsetHeight;
        this.navBarPosition = this.initialNavBarPosition + submenuHeight + this.closeOffset;
    };
    return Nav;
}());
exports.default = Nav;
